import "./headder-jager.css";
import swlogo from "../../imagini/swlogo.png";

const Headder = () => {
  return (
    <div>
      <div className="App-header">
        <img class="img2" src={swlogo} className="App-logo" alt="logo" />
        <div>
          
          <h1
            style={{ margin: "1px", textAlign: "center", fontFamily: "monospace", fontSize: "40px" }}
            className="Menu-title"
          >
            {/* <span style={{ color: "#a28b8b" }}>MENU</span> */}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Headder;
